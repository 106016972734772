// Variable overrides

// colors
$login_register_bgr__wrapper: #2F3F4F;
$login_register_card__form: rgba(255,255,255,.8);
$top_line_button: rgb(164,164,189);
$table_header_bgr: rgb(245, 246, 250);
$table_header_color: rgb(168, 172, 184);
$table_td_color: rgb(91, 94, 105);
$table_input_bgr: rgb(240,240,247);
$table_selected_bgr: rgb(220,220,227);
$pink_background: rgb(249,82,111);
$pink_light_background: rgb(252,168,182);
$darkgrey_background: rgb(113,113,132);
$top_line_btn__bgr_dark: rgb(66,66,92);
$table_btn__confirm_bgr: rgb(60,215,151);
$bottom_line_btn__bgr: rgb(210,209,222);
$subtitle_font_color: rgb(166,176,173);
$create_rfq_progress_color: #37d896;
$generated_po_color: rgb(127,177,238);
$deactivated_color: rgb(252,191,61);
$spinner_color: #140043;


// margins & padding

// fonts
$titleBig: 48px;
$titleMedium: 36px;
$titleSmall: 24px;

$screen-nd: 1200px;