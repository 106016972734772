// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';
@import './scss/mixins.scss';
@import './views/Pages/Login/styles.scss';

@media (min-width: 992px) {
  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main {
    margin-left: 260px;
  }
}

html:not([dir="rtl"]) .sidebar {
  margin-left: 0 !important;
  width: 60px !important;

  &:hover {
    width: 260px !important;

    .sidebar-header {
      .navbar-brand-full {
        transform: translateX(0px);
        transition: all .15s ease-in-out;
      }
    }
  }

  .sidebar-header {
    .navbar-brand-full {
      transform: translateX(-80px);
      transition: all .15s ease-in-out;
    }
  }

  .sidebar-nav {
    width: 100% !important;
  }
}

html:not([dir="rtl"]) .sidebar-lg-show .sidebar,
html:not([dir="rtl"]) .sidebar-show .sidebar {
  width: 260px !important;

  .sidebar-header {
    .navbar-brand-full {

    }
  }
}

.sidebar {
  overflow: hidden;
  // left: 4px;
}

.sidebar .nav,
.sidebar .sidebar-nav {
  //width: 100% !important;
  width: 260px !important;

  li {


    &:hover {

    }

    .nav-link {


      &:hover {

      }
    }
  }
}

.component__wrapper {
  max-width: 1170px;
  width: 100%;
  //width: 1170px;
}

.MuiTypography-body1 {
  font-family: 'Source Sans Pro', Regular !important;
  letter-spacing: 0;
  color: #43425D;
  opacity: 1;
  font-size: 15px !important;
}
.MuiButton-text {
  font-family: 'Source Sans Pro', Regular !important;
}
h6 {
  font-family: 'Source Sans Pro', Regular !important;
  font-size: 18px !important;
  margin-top: 4px !important;
}

::-webkit-scrollbar {
  display: none;
}

button {
  outline: none !important;
  box-shadow: none;
}

.sort_icon_desc_wrapper, .sort_icon_asc_wrapper {
  .a {
    stroke: none;
    fill: white;
  }
}

.loader {
  margin: auto auto;
  border: 16px solid #F3F3F3;
  border-top: 16px solid rgb(0, 2, 63);
  margin-top: 80px;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  }

  @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
  }
