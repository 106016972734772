@import "variables";
@import "mixins";

.table-responsive {
  //min-height: 75vh;
  max-width: 1170px;
  width: 100%;
  // overflow: visible !important;
}

.table_main__wrapper {
  display: flex;
  flex-direction: column;
  //width: 1170px;
  .pag {
    @include flex_row__wrapper;
    width: 100%;
    align-items: center;
    margin-top: 19px;
    .add_approve__button {
      width: 96px;
      font-size: 11px;
      height: 33px;
      min-height: 33px;
      margin-right: 20px;
      background: #F25270 0% 0% no-repeat padding-box;
      color: white;
    }
    .add_disapprove__button {
      width: 106px;
      font-size: 11px;
      height: 33px;
      min-height: 33px;
      background: #43425D 0% 0% no-repeat padding-box;
      color: white;
    }
    .nav__pagination {
      align-self: flex-end;
      //margin-top: 44px;
      .pagination {
        display: flex;
        justify-content: flex-end;
        width: 295px;
        height: 32px;
        font-size: 13px;
        color: #4D4F5C;
        margin-bottom: 0;
        //box-shadow: 0 0 0.5px 0 rgba(0,0,0, .2);
        .page-link {
          border: none;
          color: #4D4F5C;
        }
        .page-link:hover {
          //border: 1px solid #fff;
          color:  #F25270;
          background: white;
        }
        .active {
          .page-link {
            color: #fff;
            background: #F25270;
            border-color:  #F25270;
          }
        }
      }
    }
  }

}

.table_row__wrapper {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiIconButton-colorSecondary {
    color: transparent !important;
  }
  .MuiIconButton-colorSecondary:hover {
    background-color: white;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: $pink_background !important;
  }
  .MuiCheckbox-colorSecondary.Mui-checked:hover {
    background: none;
  }
  .MuiOutlinedInput-input {
    margin: 5px;
    color: black;
    background: #2F3F4F;
  }
}

.input_control {
  background-color: $table_input_bgr;
  height: 20px;
  text-align: center;
}

.form-control {
  border: none;
  font-size: 13px;
  width: 32px;
  padding: 0;
}

.page_card_main_textarea__field {
  .form-control {
    color: rgb(48, 44, 179) !important
  }
}

.input-group-prepend .btn-primary {
  border: none
}

.form-control.is-invalid {
  background-image: none;
}

.table_row__input {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: none !important;
  margin: 0 auto;
  padding-left: 5px;

  .vendor_item {
    width: 66px;
  }

  .form-group {
    margin: 0;
  }

  .custom02 {
    width: 17px;
    height: 17px;
  }
  .custom02 input[type="checkbox"] {
    display: none;
  }
  .custom02 label {
    @include flex_row__wrapper;
    position: relative;
    cursor: pointer;
    height: 20px;
    width: 20px;
    -webkit-transition: all .2s;
    transition: all .2s;
    margin: 0;
  }
  .custom02 label::before,
  .custom02 label::after {
    position: absolute;
    content: '';
    -webkit-transition: all .2s;
    transition: all .2s;
  }
  .custom02 label::before {
    top: 50%;
    left: 0;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    background: #f0f0f7;
    border: none;
    border-radius: 3px;
  }
  .custom02 label::after {
    opacity: 0;
    top: 50%;
    left: 5px;
    width: 10px;
    height: 7px;
    margin-top: -5px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    -webkit-transform: rotate(-45deg) scale(.5);
    transform: rotate(-45deg) scale(.5);
  }
  //.custom02 label:hover::before {
   // background: #fff;
  //}
  .custom02 input[type="checkbox"]:checked + label::before {
    background: $pink_background;
  }
  .custom02 input[type="checkbox"]:checked + label::after {
    opacity: 1;
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
  }


  .table_row__input_wrap {
    width: 32px;
    margin-left: 6px;
  }

  .MuiFormControl-marginNormal {
    margin: 0;
  }
  .table_select, .table_select_option {
    background-color: $table_input_bgr;
    border: none;
    height: 20px;
  }

  .form-control.is-invalid {
    background-image: none;
  }

  .table_select_option {
    background-color: $table_input_bgr;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiOutlinedInput-input {
    margin: 0;
    padding: 0;
    border: none;
    color: black;
    text-align: center;
    font-size: 12px;
    height: 20px;
    min-width: 40px;
    border-radius: 5px;
    background-color: $table_input_bgr;
  }
}

.table_header__wrapper {
  color: white;
  font-size: 11px;
  width: 1170px;
  background: $top_line_button;
  //white-space: nowrap;
  .sort_icons {
    @include flex_row__wrapper;
    .sort_icons__wrapper {
      @include flex_column__wrapper;
      margin-right: 3px;
      width: 10px;
      .sort__icon {
        width: 8px;
        height: 6px;
        opacity: .35;
      }
    }
  }

  .sort_icons:last-child {
    justify-content: center;
  }

  th:last-child {
    text-align: center;
  }

  .table_header {
    border: none;
    border-right: 1px solid #A5A4BF;

    &.qty {
      width: 80px;
    }

    &.actions {
      width: 85px;

      .sort_icons {
        justify-content: center !important;
      }

      .actions_text {
        text-align: center;
      }
    }

    &.description {
      width: 250px;
    }

    &.wtlbs {
      width: 70px;

      .sort_icons {
        justify-content: center !important;
      }
    }
  }
}

.table_header__body_stocklist {

  .MuiSvgIcon-root {
    background: $table_input_bgr;
    height: 1em;
    width: 1em;
    border-radius: 5px;
    border: none;
    input {
      border: none;
    }
  }
  background: white;
  .table_td_stocklist, .table_td_stocklist_general {
    border-bottom: 0.5px solid rgba(0,0,0, .1);
    vertical-align: middle;
    text-align: center;
    color: $table_td_color;
    word-break: break-all;
    box-sizing: border-box;
    &:hover{
      background-color: #b4cce7;
      cursor: pointer;
  }
    .table_button__confirm,
    .table_button__cancel {
      border-radius: 50%;
      width: 21px;
      height: 21px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      .action_btn_icon {
        width: 10px;
        height: 10px;
      }
    }
    .table_button__confirm {
      background: $table_btn__confirm_bgr;
    }
    .table_button__cancel {
      background: $pink_background;
    }
    .MuiCheckbox-root {
      border-color: transparent !important;
    }
  }
  .table_price__btn {
    border: none;
    position: relative;
    border-radius: 2px;
    height: 13px;
    color: white;
    background-color: rgb(109,173,251);
    width: 16px;
    padding: 0;
    overflow: hidden;
    .btn_icon {
      width: 30px;
      top: -5px;
      right: -8px;
      position: absolute;
    }
  }

  .table_btn__wrapper {
    @include flex_row__wrapper;
  }

  .table_edit__btn,
  .table_create__btn,
  .table_delete__btn {
    @include flex_row__wrapper;
    align-items: center;
    outline: none;
    height: 21px;
    width: 21px;
    border-radius: 50%;
    border: none;
    margin: 0 auto;
    .btn__separator {
      display: none;
    }
    .table__icon,
    .btn_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 10px;
      margin: 0 auto;
    }
  }

  .table_edit__btn {
    .a, .b {
      fill: #a3a0fb;
    }

    &:hover svg .a {
      fill: #7f7be9;
    }

    &:hover svg .b {
      fill: #5d59d1;
    }
  }



  .table_edit__btn:hover {
    background-color: rgb(160,161,202);
  }

  .table_delete__btn {
    background-color: rgba(255,177,177, 0);
    margin: 0;
    padding: 0;
    .btn_icon {
      width: 21px;
      height: 21px;
    }
  }

  .table_delete__btn:hover {
    background-color: rgba(255,160,160, .3);
  }

  .table_create__btn {
    background-color: rgba(16, 98, 229, .5);
  }
  .table_create__btn:hover {
    background-color: rgb(100,153,240);
  }

  .selectInput {
    border: 1px solid red;
  }

  .table__select {
    position: relative;
    width: 200px;
  }

  .css-yk16xz-control,
  .css-1pahdxg-control {
    border: none;
    background: $table_input_bgr;
    box-shadow: none;
    .css-1hwfws3 {
      position: initial;
      overflow: hidden !important;
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }

  .css-26l3qy-menu {
    position: absolute;
    background: $table_input_bgr;
    margin: 0;
    left: 90%;
    .css-1ml51p6-MenuList {
      padding: 0;
      background: white;
      .css-9gakcf-option:active {
        background-color: $table_input_bgr !important;
      }
    }
  }

  .css-yk16xz-control:active {
    border: none;
  }

  .table_actions__wrapper {
      @include flex_row__wrapper;
      //padding-right: 10.72px;
      justify-content: center;

    & > * {
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }

    div:not(:last-child) {
      //margin-right: 4.76px;
    }
    .table_btn__wrapper {
      //margin: 0 10px;
    }


    .action_btn_icon {
      //width: 15px;
    }
  }
}

.switch_table {
  @include flex_row__wrapper;
  margin-right: 40px;
  .switch-slider {
    color: white;
    background: $pink_background;
    width: 105px;
  }

  .switch-label .switch-slider::after {
    left: 25px;
    color: white;
    font-size: 10px;
    font-weight: bolder;
  }

  .switch-outline-secondary-alt .switch-input:checked + .switch-slider {
    background: rgb(55,216,150);
    width: 90px;
  }
  .switch-outline-secondary-alt .switch-input:checked + .switch-slider::after {
    color: white;
  }

  .switch-label .switch-input:checked ~ .switch-slider::after {
    left: 5px;
  }

  .switch-outline-secondary-alt .switch-input:checked + .switch-slider::before {
    background: white;
    border: none;
    transform: translate(64px);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.table_header__body .table_td, .table_header__body td {
  padding: 16px 5px 17px;
  font-size: 13px;
  color: #4d4f5c;
  border-top: none;
}
.input_control{
  width: 100%;
}

.table_td_stocklist_general {
  &:first-child {
    padding-left: 0;
  }
}
