.app-body {
  margin-top: 0 !important;
}

.app-header {
  height: 70px !important;
  border-bottom: none !important;
  display: flex !important;
  align-items: center !important;
  padding-right: 22px;

  &__list-icons {
    padding: 0 4px 0 0;
    list-style: none;
    margin-left: 0;
    font-size: 16px;

    .nav-link {
      .badge {
        display: block;
        background: red;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        padding: 0;
        margin: -12px 0px 0px 4px !important;
      }
    }
  }
}

.app-header .navbar-nav {
  display: flex;
  align-items: center;

  & > * {
    position: relative;
    margin-right: 20px;

    &:after {
      content: "";
      position: absolute;
      transform: translate(0, -50%);
      top: 50%;
      right: 0;
      height: 28px;
      width: 1px;
      background-color: #EBEBF2;
    }

    &:last-child {
      margin-right: 0;
      border-right: none;

      &:after {
        display: none;
      }
    }
  }
}

.app-header .nav .input-group {
  align-items: center;
  height: 70px;

  .btn {
    box-shadow: none !important;
  }

  .form-control {
    color: #030041;
    height: 100%;
    outline: none;
    box-shadow: none;
  }
}

/*.header-dropdown {*/
  /*position: fixed;*/
  /*right: 0%;*/
/*}*/

.container-fluid {
  border-top: 1px solid #c8ced3;
  margin-top: 70px !important;
  padding: 0 75px 0px 135px !important;
}

.centrafab-table {
  thead {
    white-space: nowrap;

    tr {
      th {

      }
    }

    .status {
      width: 150px;
    }
  }

  tbody {
    tr {
      td {
        //padding-top: 8px !important;
        //padding-bottom: 8px !important;
      }
    }
  }

  .num {
    width: 100px;
  }

  .job {
    width: 150px;
  }

  .date {
    width: 180px;
  }

  .status {
    //width: 150px;
    text-align: center;

    .thIcons {
      justify-content: center;
      text-align: center;
    }
  }

  .revision {
    width: 105px;
  }

  .actions {
    width: 80px;
    padding-left: 0 !important;
    text-align: center;

    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;

      & > * {
        white-space: nowrap;
        margin-right: 15px;
        margin-bottom: 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        position: relative;

        &.actions {

        }
      }
    }
  }

  .btn-open-children {
    //position: absolute;
    //right: 0px;
    //top: 0px;
  }
}

.sidebar {
  padding-top: 70px !important;
  background: rgb(0, 2, 63) !important;
}

.nav-link {
  height: 53px !important;
}

.nav-dropdown.open .nav-link {
  vertical-align: middle;
}

.nav-dropdown-items {
  padding-top: 6px;
}

.nav-dropdown-items .nav-item {
  //height: 30px;
  margin: 10px 0;
}

.nav-dropdown-items .nav-item .nav-link {
  display: flex;
  vertical-align: middle;
  //height: 29px !important;
  //padding: 10px 0;
  font-size: 12px;
  height: auto !important;
  padding: 5px 42px 5px 45px !important;

  &.active {
    box-shadow: none;
  }
}

.sidebar .nav-link.active {
  background: rgb(0, 2, 40) !important;
  color: #20a8d8 !important;
}

.nav-dropdown-items .nav-item a {
  font-size: 12px !important;
  padding: 5px 20px !important;
}

/*  */
.component__wrapper .table {
  /*margin: 0 auto;*/
  transition: margin 0.25s, width 0.25s;
}
.aside-menu-off-canvas .component__wrapper .table {
  margin: 0 auto;
}


// open menu
.aside-menu-off-canvas.sidebar-lg-show {
  .app-header {

  }
  .sidebar {
    .nav-link {
      .nav-icon {
        //position: relative;
        //transform: none;
        //top: auto;
        //right: auto;
      }
    }
  }
}

// close menu
.aside-menu-off-canvas {
  //margin-left: 0 !important;

  .app-header {

  }
  .sidebar {
    .nav-link {
      position: relative;

      .nav-icon {
        //position: absolute;
        //transform: translate(0%, -50%);
        //top: 50%;
        //right: 10px;


      }
    }

    .nav-dropdown-toggle {
      &:before {
        right: 10px;
      }
    }
  }
}
