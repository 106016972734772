@import "variables";
@import "mixins";

.select__wrapper {
  @include flex_row__wrapper;
  justify-content: flex-end;
  width: 50%;

  .css-2b097c-container {
    width: 269px;
    height: 48px;
    .css-26l3qy-menu {
      //margin-top: 8px !important;
    }
    .css-1uccc91-singleValue {
      width: 205px;
      font-size: 18px !important;
    }
    .css-tj5bde-Svg {
      fill: white;
    }
  }

  .select__list {
    //width: 30%;
    max-width: 280px;

    .css-yk16xz-control,
    .css-1pahdxg-control {
      border: none;
      background: $pink_background;
      box-shadow: none;
      color: white;

      .css-1hwfws3 {
        position: initial;
        overflow: hidden !important;
      }

      .css-1okebmr-indicatorSeparator {
        display: none;
      }
    }

    .css-26l3qy-menu {
      position: absolute;
      background: $table_input_bgr;
      margin: 0;
      left: 90%;
      .css-1ml51p6-MenuList {
        background: white;
        color: rgba(77, 79, 92, .5);;
        ::-webkit-scrollbar {
          width: 0px;  /* Remove scrollbar space */
          background: transparent;  /* Optional: just make scrollbar invisible */
          // display: none;
        }
        padding: 0;
        .css-9gakcf-option:hover,
        .css-1n7v3ny-option:hover {
          background: rgba(163, 160, 251, .08) 0% 0% no-repeat padding-box;
        }
        .css-9gakcf-option {
          background-color: $table_input_bgr !important;
          color: #4D4F5C;
          opacity: 0.5;
        }
      }
    }

    .css-yk16xz-control:active {
      border: none;
    }
  }

  .select__list:active {
    border-color: $pink_background;
    background: $pink_background;
  }

  .css-yk16xz-control,
  .css-1pahdxg-control {
    border-color: $pink_background;
    height: 50px;
    box-shadow: none;

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-1uccc91-singleValue {
      font-size: 1.2em;
      font-weight: bold;
      margin-left: 10px;
      color: inherit;
    }
  }

  .css-1n7v3ny-option,
  .css-9gakcf-option:hover {
    background: $table_input_bgr;
    border-left: 2px solid $pink_background;
  }

  .css-1n7v3ny-option {
    background: white;
  }

  .css-9gakcf-option {
    background: white;
    color: #4D4F5C;
    border-radius: 4px;
    opacity: 0.5;
    height: 35px;
    font-size: 13px;
  }

  .css-1pahdxg-control:hover,
  .css-yk16xz-control:hover {
    border-color: $pink_background;
    background: $pink_background;
    color: white !important;
    .css-tlfecz-indicatorContainer {
      color: white;
    }
  }

  .top_line__select {
    .css-26l3qy-menu {
      position: absolute;
      background: $table_input_bgr;
      margin: 0;
      left: 0;
      color: black;
      .css-1ml51p6-MenuList {
        padding: 0;
        .css-9gakcf-option:active {
          background-color: $table_input_bgr !important;
        }
      }
    }
  }
}

.page_card_main_component {
  border: none;
  padding: 36px 44px 58px 44px;
  width: 1170px;

  .card-body {
    padding: 0;
  }

  .page_card_main_info_section,
  .page_card_main_data_section {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-bottom: 29px;
    .card__title {
      font-size: 28px;
      color: #030041;
      height: 36px;
      margin-bottom: 10px;
    }
    .card__subtitle {
      font-weight: bolder;
      font-size: 16px;
      height: 20px;
      color: #4D4F5C !important;
      opacity: 0.5;
      margin-bottom: 3px;
    }
    .card__info {
      font-size: 14px;
      color: #4D4F5C;
      opacity: 0.5;
    }
    .card__info__title {
      font-size: 22px;
      color: #4D4F5C;
      opacity: 0.5;
      height: 28px;
    }
    .card__info__submitter {
      font-size: 22px;
      text-align: left;
      font: Semibold 22px/25px Source Sans Pro;
      letter-spacing: 0;
      color: #4D4F5C;
      opacity: 1;
      height: 28px;
      margin-bottom: 50px;
    }
  }

  .page_card_main_data_section {
    padding-top: 50px;
    padding-bottom: 59px;

    .page_card_main_textarea__field {
      margin: 0;
      textarea {
        background: $table_input_bgr;
        width: 1082px;
        background: #F5F6FA 0% 0% no-repeat padding-box;
        border-radius: 4px;
        opacity: 1;
        padding: 14px 11px;
        font-size: 13px;
        color: #A5A4BF !important;
      }
      margin-bottom: 82px;
    }

    .page_card_main_firm_fields__wrapper {
      @include flex_row__wrapper;

      .page_card_main_firm_fields__div {
        width: 48%;
        .card__info_subbtitle {
          font-size: 22px;
          color: #4D4F5C;
          opacity: 0.5;
          margin-bottom: 12px;
        }

        .page_card_main_firm_section_fields__wrapper {
          @include flex_row__wrapper;

          .page_card_main_firm_item_fields__wrapper {
            width: 250px;
            margin-right: 17px;
            border-radius: 4px;
            .form-control {
              width: 250px;
              height: 40px;
              font-size: 13px;
              padding: 12px 16px 11px 16px;
            }
            .select__list {
              max-width: 250px;
             .css-yk16xz-control {
               height: 40px;
             }
          }
            //.select__list {
            //  max-width: 250px;
            //
            //  .css-yk16xz-control,
            //  .css-1pahdxg-control {
            //    border: none;
            //    background: $table_input_bgr;
            //    box-shadow: none;
            //    color: white;
            //
            //    .css-1hwfws3 {
            //      position: initial;
            //    }
            //
            //    .css-1okebmr-indicatorSeparator {
            //      display: none;
            //    }
            //  }
            //
            //  .css-26l3qy-menu {
            //    position: absolute;
            //    background: $table_input_bgr;
            //    left: 90%;
            //  }
            //
            //  .css-yk16xz-control:active {
            //    border: none;
            //  }
            //
            //  .css-yk16xz-control,
            //  .css-1pahdxg-control {
            //    box-shadow: none;
            //
            //    .css-1okebmr-indicatorSeparator {
            //      display: none;
            //    }
            //
            //    .css-1uccc91-singleValue {
            //      margin-left: 5px;
            //      color: #5c6873;
            //      font-size: 0.875rem;
            //    }
            //  }
            //
            //  .css-1n7v3ny-option,
            //  .css-9gakcf-option:hover {
            //    background: $table_input_bgr;
            //  }
            //
            //  .css-9gakcf-option {
            //    background: $table_selected_bgr;
            //  }
            //
            //  .css-1pahdxg-control:hover,
            //  .css-yk16xz-control:hover {
            //    //border-color: $pink_background;
            //    //background: $pink_background;
            //  }
            //}
            @include select__list;

            .page_card_main_form_group__field {
              width: 100%;
              margin-bottom: 0;
              input {
                background: $table_input_bgr;
              }
              .css-tj5bde-Svg {
                fill: #A5A4BF;
              }

              .page_card_main_form_inputs__wrapper {
                @include flex_row__wrapper;
                margin-top: 19px;
                .select__list {
                  width: 50%;
                  height: 40px;
                  .css-yk16xz-control {
                    min-height: 30px;
                  }
                }
                .page_card_main_form_input,
                .css-yk16xz-control {
                  width: 118px;
                }
              }
            }
          }
          .page_card_main_firm_item_fields__wrapper:last-child {
            margin-right: 0;
          }
        }

      }
    }
  }

  .rfq_po_table {

    .table {
      margin-bottom: 0;
    }

    .form-control {
      margin: 0 auto;
    }

    .delivery_input,
    .cost_per_one_input,
    .quantity_input {
      height: 21px;
      border-radius: 82px;
      font-size: 13px;
      color: #4D4F5C;
    }

    .delivery_input {
      width: 93px;
    }

    .cost_per_one_input {
      width: 55px;
    }

    .quantity_input {
      width: 36px;
      background: rgba(163, 160, 251, .25) 0% 0% no-repeat padding-box;
      border-radius: 10px;
      color: #4D4F5C;
    }

    .line {
      width: 67px;
    }
    .mfg_no {
      width: 71px;
      padding-top: 11px;
      vertical-align: middle;
      .mfg_no_text {
        text-align: center;
      }
    }
    .mfg_name {
      width: 96px;
      .mfg_name_text {
        padding-top: 5px;
        white-space: nowrap;
        width: 51px;
      }
    }
    .cf_no {
      width: 87px;
      .cf_no_text {
        padding-top: 5px;
        white-space: nowrap;
        width: 37px;
      }
    }
    .vendor_name {
      width: 94px;
    }
    .unit {
      width: 72px;
    }
    .quantity {
      width: 81px;
    }
    .description {
      width: 114px;
      .description_text {
        white-space: nowrap;
      }
    }
    .delivery {
      width: 138px;
    }
    .cost_per_one {
      width: 115px;
    }
    .total_price {
      width: 107px;
    }

  }

  .page_card_main_bottom_line__buttons {
    .css-26l3qy-menu {
      width: 119px;
      color: #4D4F5C;
      opacity: 0.5;
    }
    .css-9gakcf-option:hover {
      background: #E9E9F0 0% 0% no-repeat padding-box !important;
    }
  }

  .sub_info {
    font-size: 15px;
  }

  .page_card_main_table {
    margin-top: 50px;
  }
  .page_card_main_bottom_line,
  .page_card_main_bottom_buttons,
  .page_card_main_bottom_price {
    @include flex_row__wrapper;
    .page_card_main_bottom_price_text {
      font-size: 28px;
      padding-bottom: 19px;
      margin: 0;
    }
  }
  .page_card_main_bottom_price {
    font-size: 28px !important;
  }

  .page_card_main_bottom_line {
    margin-top: 36px;
    .page_card_main_bottom_line__buttons {
      @include flex_row__wrapper;
      @include select__list;
      .page_card_main_add_part__button {
        width: 68px;
        min-width: 68px;
        max-width: 68px;
      }
      .select__list {
        width: 124px;
        margin-left: 41px;
      }
    }
  }

  .page_card_main_bottom_price,
  .page_card_main_bottom_buttons {
    justify-content: flex-end;
  }
}

.edit_product_type__card {
  min-width: 300px;
  padding: 30px;
  border-radius: 15px;
  .modal__section {
    @include flex_row__wrapper;
    flex-wrap: wrap;
  }
  .modal_text_field {
    width: 45%;
    .input__field {
      margin: 10px 0;
      background-color: $table_input_bgr;
      border-radius: 5px;
      width: 100%;
      .MuiInput-underline:before,
      .MuiInput-underline:after {
        border-bottom: none;
      }
    }
    .MuiInputBase-input {
      text-align: center;
      vertical-align: center;
      height: 25px;
      font-size: 13px;
    }
  }
}

