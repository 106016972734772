@import "variables";

@mixin flex_row__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@mixin flex_column__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@mixin btn_mixin {
  //min-width: 161px;
  height: 35px;
  border: none;
  border-radius: 5px;
  color: white;
  margin-left: 20px;
}

@mixin select__list {
  max-width: 280px;

  .css-yk16xz-control,
  .css-1pahdxg-control {
    border: none;
    background: $table_input_bgr;
    box-shadow: none;
    color: white;

  .css-yk16xz-control {
    overflow: hidden; 
    height: 40px;
  }

    .css-1hwfws3 {
      position: initial;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }

  .css-26l3qy-menu {
    position: absolute;
    background: white;
    margin: 0;
    left: 90%;
    box-shadow: 0 0 1px 0 rgba(0,0,0, .5);
    .css-1ml51p6-MenuList {
      padding: 0;
      color: #4D4F5C;
      font-size: 13px;
      background: white;
      .css-1n7v3ny-option:hover,
      .css-9gakcf-option:hover {
        background-color: $table_input_bgr
      }
      .css-9gakcf-option:active {
        background-color: black !important;
      }
    }
  }

  .css-yk16xz-control:active {
    border: none;
  }

  .css-yk16xz-control,
  .css-1pahdxg-control {
    box-shadow: none;

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-1uccc91-singleValue {
      margin-left: 5px;
      color: #A5A4BF;
      font-size: 0.875rem;
      max-width: calc(100% - 35px) !important;
    }
  }

  .css-1n7v3ny-option,
  .css-9gakcf-option:hover {
    border-radius: 4px;
    background: $table_input_bgr;
    color: #A5A4BF;
  }

  .css-1n7v3ny-option {
    background: white;
  }

  .css-9gakcf-option {
    color: black;
    background: white;
    border-radius: 4px;
    color: #A5A4BF;
  }

  .css-1pahdxg-control:hover,
  .css-yk16xz-control:hover {
    color: white;
    background: $table_input_bgr;
    //border-color: $pink_background;
    //background: $pink_background;
  }
}

/*::-webkit-scrollbar {
  display: none;
}*/
