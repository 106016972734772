@import '../../../scss/variables';
@import '../../../scss/mixins';

.login__container {
  background-color: $login_register_bgr__wrapper;
  .login_card__form {
    background: $login_register_card__form;
  }
  .login_register__wrapper {
    display: flex;
    flex-direction: column;
    .login__title {
      margin: 0 auto 3% auto;
      text-align: center;
      font-size: $titleBig;
    }
    .login_register_buttons__wrapper {
      @include flex_row__wrapper;
      .login__button,
      .register__button {
        width: 120px;
      }
    }
  }
}
