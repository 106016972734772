@import "variables";
@import "mixins";

.upload_xls,
.product_type__btn,
.add_new_types__btn {
  @include btn_mixin;
  font-size: 11px;
  background-color: $top_line_btn__bgr_dark;
  .btn__separator {
    margin: 0 6px;
    height: 26px;
  }
  .btn_icon {
    width: 13px;
    height: 13px;
    margin-right: 7px;
  }
}

.btn_icon {
  cursor: pointer;
}



.upload_xls {
  .btn_icon {
    width: 18px;
    height: 13px;
    margin-right: 2px;
  }
}

.table_delete__btn {
  .btn__separator {
    display: none;
  }
  .btn_icon {
    margin: 0 auto;
    width: 18px;
  }
}

.rfq__btn,
.po__btn,
.po_top_line__btn,
.page_card_main_add_part__button,
.page_card_main_create__button,
.page_card_main_cancel__button {
  @include btn_mixin;
  background-color: $top_line_button;
  outline: none;
  font-size: 15px;
  font-family: 'Source Sans Pro', Regular;
  .btn__separator,
  .btn_icon {
    display: none;
  }
}

.rfq__btn,
.po__btn {
  min-width: 75px;
  font-size: 11px;
  width: 45px;
}

.page_card_main_add_part__button {
  margin-left: 0;
}

.page_card_main_create__button,
.page_card_main_cancel__button {
  width: 131px;
  font-size: 15px;
}

.add_new_types__btn,
.po_top_line__btn,
.page_card_main_add_part__button {
  background-color: $pink_background;
}

.page_card_main_create__button,
.page_card_main_cancel__button {
  width: 131px;
  background-color: $bottom_line_btn__bgr;
  height: 35px;
  margin-left: 15px;
}

.select_popover__btn {
  display: flex;
  align-items: center;
  line-height: 6px;
  min-width: 66px;
  white-space: nowrap;
  height: 20px;
  background: $table_input_bgr;
  border: none;
  outline: none;

  .inner_label_popover {
    font-size: 13px;
    width: 40px;
  }

  .select_popover__btn_icon {
    margin-left: 7px;
    width: 6px;
    height: auto;
  }
  .open {
    transform: rotate(180deg);
  }
}
.select_popover__btn:hover {
  border: none !important;
}

.dropdown_toggle__wrapper {
  @include flex_row__wrapper;
}
.toggle_drop__btn {
  width: 10px;
  //display: block;
  margin-left: 5px;
}

.btn_open {
  transform: rotate(180deg);
}

.btn_icon {
  width: 10px;
}

.table_button__new_revision {
  background: $pink_background;
  border: none;
  border-radius: 5px;
  color: white;
}

.download_btn {
  background: $pink_background;
  border-radius: 5px;
  border: none;
  margin-left: 10px;
  .btn_icon {
    width: 15px;
    margin-bottom: 3px;
  }
}

.btn {
  box-shadow: 0px 3px 6px #00000029;

  &-danger {
    border-color: #F25270;
    background-color: #F25270;

    &:hover, &:focus {
      border-color: #F25270;
      background-color: #F25270;
    }
  }
}
