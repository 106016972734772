@import "variables";
@import "mixins";

.MuiDialog-paperWidthSm {
  max-width: 810px !important;
}

.modal_window__wrapper {
  width: 800px;
}

.MuiDialogContent-root {
  margin: 0 auto !important;
  padding: 0 !important;
  width: 460px;
}

.po_modal_title__wrapper {
  @include flex_row__wrapper;
  height: 49px;
  .po_modal_title {
    background: $pink_background;
    color: white;
    width: 518px;
    height: 100%;
    margin: 0;
    padding: 7px 30px;
  }
  .add_new_vendor_title {
    width: 739px;
    .MuiTypography-h6 {
      font-weight: 600;
    }
    .MuiButtonBase-root {
      width: 40px !important;
    }
  }
  .popup_close__btn {
    padding: 0;
    margin: 0 auto;
    min-width: 49px;

    &:hover {
      background-color: white;
    }
  }
  .po_modal_title__icon {
    width: 25px;

  }
}


.po_modal_dialog_content__wrapper {
  //margin: 0 38px;
  .po_modal_content__wrapper {
    width: 100%;
    box-sizing: border-box;
    color: #A5A4BF;
    .po_modal_questions {
      margin: 0 auto;
      text-align: center;
      padding-top: 50px;
    }
    .po_modal_content__table {
      margin-top: 50px;
      width: inherit;
      .table_main__wrapper {
        width: inherit;
        .table-responsive {
          width: inherit;
          min-width: 0;
          .table {
            width: inherit;
            .table_header__wrapper {
              width: inherit;
            }
          }
          td:first-child {
            padding-left: 12px;
          }
          .table_td {
            width: 529px;
          }
          .type {
            width: 194px;
            .type_text {
              padding-left: 12px;
            }
          }
          .cf_no {
            width: 170px;
            .cf_no_text {
              margin-top: 12.5px;
            }
          }
          .actions {
            .sort_icons {
              display: flex;
              justify-content: center;
            }
            .actions_text {
              margin-left: 20px;
              text-align: center;
            }
          }
        }
      }
    }
    .modal__section {
      @include flex_row__wrapper;
      margin-top: 60px;
      flex-wrap: wrap;
      .action_delete_vendor__button {
        background-color: $pink_background;
        color: white;
        font-size: 10px;
      }
      .modal__section_bottom {
        @include flex_row__wrapper;
        width: 100%;
        .modal_text_field {
          width: 30%;
        }
        .field_description {
          width: 65%;
          .input__field {
            width: 100%;
          }
        }
      }
      .modal_text_field {
        width: 30%;
        .input__field {
          margin: 10px 0;
          background-color: $table_input_bgr;
          border-radius: 5px;
          width: 100%;
          .MuiInput-underline:before,
          .MuiInput-underline:after {
            border-bottom: none;
          }
        }
        .MuiInputBase-input {
          text-align: center;
          vertical-align: center;
          height: 25px;
          font-size: 13px;
          color: #A5A4BF;

          //&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          //  color: #8a89b7;
          //}
          //&::-moz-placeholder { /* Firefox 19+ */
          //  color: #8a89b7;
          //}
          //&:-ms-input-placeholder { /* IE 10+ */
          //  color: #8a89b7;
          //}
          //&:-moz-placeholder { /* Firefox 18- */
          //  color: #8a89b7;
          //}
        }

        input.MuiInputBase-input {

          &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #4f4d79;
          }
          &::-moz-placeholder { /* Firefox 19+ */
            color: #4f4d79;
          }
          &:-ms-input-placeholder { /* IE 10+ */
            color: #4f4d79;
          }
          &:-moz-placeholder { /* Firefox 18- */
            color: #4f4d79;
          }
        }
      }
      .modal__select {
        width: 30%;
        .css-yk16xz-control,
        .css-1pahdxg-control {
          border: none;
          background: $table_input_bgr;
          box-shadow: none;
          .css-1hwfws3 {
            display: flex;
            align-items: center;
            position: initial;
            // overflow: hidden !important; ups
            .css-1wa3eu0-placeholder,
            .css-1uccc91-singleValue {
              margin: 0 auto;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              left: 12px !important;
              top: 10px;
              color: #A5A4BF;
              font-size: 14px;
              vertical-align: middle;
            }

          }
          .css-1okebmr-indicatorSeparator {
            display: none;
          }
        }
        .css-26l3qy-menu {
          position: absolute;
          // background-color: red !important;
          margin: 0;
          // left: 0px;
          .css-1ml51p6-MenuList {
            padding: 0;
            height: 150px;
            overflow: scroll;
            background-color: white;
            .css-1n7v3ny-option {
              background-color: #E9E9F0;
            }
            .css-1n7v3ny-option::selection {
              // background-color: greenyellow !important;
            }
            .css-9gakcf-option:active {
              // background-color: red !important;
            }
            .css-9gakcf-option {  //*  Selected Styles       *//
              background-color: white;
              color: rgb(196, 195, 212);
              &:hover {
                background-color: #E9E9F0;
              }
            }
          }
        }
        .css-26l3qy-option {
          overflow: scroll;

        }

        .css-yk16xz-control:active {

          // background-color: white !important;
          border: none;
        }
      }
    }
    .modal_radio_group {
      @include flex_row__wrapper;
      width: 460px;
      margin: 50px 0 50px 0;
      .MuiFormControlLabel-root {
        display: block;
        margin: 0;
        padding: 0;
        .MuiIconButton-root {
          padding-left: 0px;
        }
      }
      .MuiSvgIcon-root {
        font-size: 1.8em;
      }
    }
  }
}

.MuiBackdrop-root {
  background-color: rgba(3, 0, 65, 0.5) !important;
}

.add_new_vendor_wrapper {
  .MuiDialogContent-root {
    width: 680px;
  }
}

.MuiDialogActions-root {
  justify-content: space-between !important;
  padding: 8px 0 !important;
}

.po_modal_actions__wrapper {
  @include flex_row__wrapper;
  width: 100%;
  margin: 70px 0 50px 0;
  .action_cancel__button,
  .action_submit__button {
    width: 104px;
    height: 33px;
    color: white;
    font-size: 11px;
    background: rgba(67, 66, 93, .75) 0% 0% no-repeat padding-box;
    box-shadow: 0 3px 3px 0px rgba(0,0,0, .3);
    border-radius: 4px;
    opacity: .75;
  }
  .add__button {
    width: 104px;
    height: 33px;
    color: white;
    font-size: 11px;
    background-color: #CC314E;
    box-shadow: 0 3px 3px 0px rgba(0,0,0, .3);
    border-radius: 4px;
    opacity: .75;
  }
  .action_submit__button:hover,
  .add__button:hover {
    min-width: 100px;
    background-color: #CC314E;
    color: white;
    opacity: 1;
  }
  .action_cancel__button:hover {
    opacity: 1;
  }
  .action_add_vendor__button,
  .edit_product_type__btn {
    min-width: 100px;
    min-height: 35px;
    color: $pink_background;
    border: 1px solid $pink_background;
    font-size: 10px;
    outline: none;
  }
  .action_add_vendor__button:hover {
    border-color: #CC314E;
    color: #CC314E;
    background-color: white;
  }
  .action_cancel__button {
    background: #43425D;
  }
  .action_cancel__button:hover {
    background-color: #2F2E50;
  }
  .action_submit__button {
    background: $pink_background;
  }
}

.edit_product_type__btn_edit {
  min-width: 100px;
  min-height: 35px;
  color: white;
  border: 1px solid $pink_background;
  background-color: $pink_background;
  border-radius: 5px;
  outline: none;
}

.po_modal_selects__wrapper {
  @include flex_row__wrapper;
  width: 100%;
  .po_modal_select {
    min-width: 200px;
  }
  .po_modal_select:active {
    border-color: $pink_background;
    background: $pink_background;
  }
  .css-yk16xz-control,
  .css-1pahdxg-control {
    border-color: $pink_background;
    height: 50px;
    box-shadow: none;
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-1uccc91-singleValue {
      font-size: 1.2em;
      font-weight: bold;
      margin-left: 10px;
      color: inherit;
    }
  }

  .css-1n7v3ny-option,
  .css-9gakcf-option:hover {
    background: $table_input_bgr;
    border-left: 2px solid $pink_background;
  }
  .css-1n7v3ny-option {
    background: white;
  }
  .css-9gakcf-option {
    background: white;
    color: black;
  }

  .css-1pahdxg-control:hover,
  .css-yk16xz-control:hover {
    border-color: $pink_background;
    background: $pink_background;
    color: white !important;
    .css-tlfecz-indicatorContainer {
      color: white;
    }
  }

}
.css-w8afj7-Input {
  position: absolute;
  left: 18px;
}

.label-span{
  position: absolute;
  top: -6px;
  left: 2px;
  font-weight: bolder;
  font-size: 13px;
  color: rgb(77, 77, 77);
}

.input-item{
  position: relative;
  display: inline-block;
  padding-top: 15px;
  input, textarea{
    color: rgb(165, 164, 191);
    padding-left: 10px !important;
  }
  .form-control:focus{
    color: rgb(165, 164, 191);
    outline: none;
    box-shadow: none !important;
    border: none;
  }
  .error_text{
    font-size: 10px;
  }
  .red-borders {
    border: 1px solid red !important;
    border-radius: 4px;
  }
}

.contacts_validation_errors_wrapper{
  margin-bottom: 20px;
  .error_text{

  }
}
