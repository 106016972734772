// If you want to override variables do it here
@import "variables";
@import "mixins";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";
@import "table";
@import "modals.scss";
@import "buttons";
@import "components";

body {
  background: rgb(240,240,247);
}

.component__wrapper {
  margin: 0 auto;
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 0 !important;
  .main__spinner {
    @include flex_row__wrapper;
    justify-content: center;
    width: 100%;
    margin: 33% auto;
  }
  .top_line__wrapper {
    //max-width: 1170px;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 32px;
    .page__title {
      white-space: nowrap;
      font-size: 28px;
      margin-right: 42px;
    }

    .top_bars__wrapper {
      @include flex_row__wrapper;
      //width: 80%;
      align-content: flex-start;
      justify-content: flex-start;
    }

    .top_line_buttons__wrapper {
      @include flex_row__wrapper;
    }

    .page_top_line {
      display: flex;
      min-width: 250px;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.select_popover__main {
  position: relative;
  background: $table_input_bgr;
  border-radius: 5px;
}

.select_popover__wrapper {
  background: white;
  min-width: 119px;
  .select_popover {
    padding: 0;
    left: 200px;
    top: -100px;
    width: 100%;
    background-color: white;
    .select_popover__option {
      display: flex;
      height: 35px;
      align-items: center;
      padding-right: 10px;
      .MuiIconButton-colorSecondary:hover {
        background-color: white;
      }
    }
    .select_popover__option:hover {
      background: $table_input_bgr;
    }
    .no_options {
      padding: 10px;
    }
    .no_options:hover {
      padding: 10px;
    }
  }
}

.header-dropdown__wrapper {
  @include flex_row__wrapper;
  .header-dropdown {
    margin-right: 10px;
  }
  .img-avatar__wrapper {
    height: 40px;
    //margin-right: 10px;
    .img-avatar {
      height: 100%
    }
  }
}

.app-logo-wrapper {
  img {
    margin-left: 35px;
  }
  .navbar-brand-minimized {
    margin: 0;
  }
}

.header_search__field {
  .btn-primary {
    border-color: #e4e7ea;
    background-color: white;
    color: rgba(0,0,0, .3);
  }
}

.app-header .nav-item {
  .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.header-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;

  .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
  }
}

.table thead th {
  vertical-align: middle;
  font-size: 11px;
  padding: 0 5px;
}

.table td {
  padding: 16px 5px 17px 5px;
  font-size: 13px;
  color: #4D4F5C;
  border-top: none;
}

.MuiRadio-root {
  color: rgba(242, 82, 112, .5) !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: rgba(242, 82, 112, .9) !important;
}

.MuiIconButton-colorSecondary:hover {
  background-color: white !important;
}

.MuiPaper-rounded {
  border-radius: 0 !important;
}

.qty_number {
  width: 60px;
  padding-left: 13px;
  .qty_number_text {
    width: 34px;
    height: 28px;
  }
}

.cf_no_text {
  width: 42px;
  height: 28px;
}

.vendors {
  width: 50px;
  .vendors_text {
    width: 41px;
    height: 28px;
  }
}

.mfg_no_text {
  width: 42px;
  height: 28px;
}

.mfg_name {
  width: 53px;
  .mfg_name_text {
    width: 28px;
    height: 28px;
  }
}

.product_type {
  width: 50px;
  .product_type_text {
    width: 50px;
    height: 28px;
  }
}

.width_text {
  width: 35px;
}

.length_text {
  width: 39px;
}

.description_text {
  //width: 66px;
}
.material_text {
  width: 50px;
}
.grade_text {
  width: 33px;
}
.finish_text,
.wtlbs_text {
  width: 33px;
}
.qty {
  .qty_text {
    width: 37px;
  }
}
.price_text {
  width: 29px;
}
.in_demand_text {
  width: 45px;
}
.min_text {
  width: 19px;
}
.actions {
  .actions_text {
    width: 42px;
  }
}
.company_name {
  width: 254px;
  text-align: right;
}
